<ul class="share">
    <li class="heading">
        <h3>Share</h3>
    </li>
    <li class="sharable-item" (click)="OnClickedSharingItem('facebook')" action-tracking action-tracking-values="Link,Click,Share Facebook-Bubble">
        <pb-icon name="facebook"/>
        <a>Facebook</a>
    </li>
    <li class="sharable-item" (click)="OnClickedSharingItem('twitter')" action-tracking action-tracking-values="Link,Click,Share Twitter-Bubble">
        <pb-icon name="x"/>
        <a>X</a>
    </li>
    <li class="sharable-item" (click)="OnClickedSharingItem('mail')" action-tracking action-tracking-values="Link,Click,Share Email-Bubble">
        <pb-icon name="mail"></pb-icon>
        <a>Email</a>
    </li>
    <li class="sharable-item" (click)="OnClickedSharingItem('whatsapp')" action-tracking action-tracking-values="Link,Click,Share Whatsapp-Bubble">
        <pb-icon name="whatsapp"></pb-icon>
        <a>WhatsApp</a>
    </li>
    <li class="sharable-item" (click)="OnClickedSharingItem('clipboard')" action-tracking action-tracking-values="Link,Click,Copy to clipboard-Bubble">
        <pb-icon name="copy"></pb-icon>
        <a>Copy link</a>
    </li>
    <li class="share-footer">
        <input type="text" readonly value="{{copyLinkShareUrl}}" #copyLinkInput/>
    </li>
</ul>
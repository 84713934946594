import { ViewChild, ElementRef, EventEmitter, Output, Directive } from "@angular/core";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { SharingService } from "src/app/services/sharing.module/sharing.service";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";



@Directive()
export class SharingComponentBase {
    @ViewChild('copyLinkInput') private copyLinkInput: ElementRef;
    private _componentModel: ShareComponentModel = new ShareComponentModel();

    isMobileApp: boolean = false;

    set componentModel(value: ShareComponentModel) {
        this._componentModel = value;
    }
    get componentModel(): ShareComponentModel {
        return this._componentModel;
    }

    @Output() onRequestClosePopup: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        protected shareService: SharingService,
        protected applicationUrlsService: UrlHelpersService,
        protected platformService: PlatformHelpersService,
        protected trackingService: TrackingService,
        protected applicationStateService: ApplicationStateService
    ) {
        if (this.platformService.IsBrowserPlatform) {
            this._componentModel.shareUrl = this.platformService.Window.location.href;
            //this.isMobileApp = this.applicationStateService.isMobileApp;
        }
    }

    PerformShare(type: string) {
        if (this.platformService.IsBrowserPlatform) {
            var url = "";
            switch (type) {
                case "facebook":
                    this.shareService.ShareFacebook(this.componentModel.subHeader, this.GetUtmUrl("facebook"));
                    break;
                case "twitter":
                    this.shareService.ShareTwitter(this.componentModel.shareSocialMediaText, this.componentModel.subHeader, this.GetUtmUrl("twitter"));
                    break;
                case "mail":
                    this.shareService.ShareMail(this.componentModel.shareSubjectText, this.componentModel.shareContentText, this.GetUtmUrl("mail"));
                    break;
                case "whatsapp":
                    this.shareService.ShareWhatsapp(this.GetUtmUrl("whatsapp"), this.componentModel.shareContentText);
                    break;
                case "clipboard":
                    this.shareService.CopyToClipboard(this.copyLinkInput);
            }
        }
        this.onRequestClosePopup.emit();
    }

    GetUtmUrl(source: string) {
        if (!this.componentModel || !this.componentModel.shareUrl){
            return "";
        }
        return this.applicationUrlsService.AddQueriesToPath(this.componentModel.shareUrl,
            [
                { key: "utm_source", value: source },
                { key: "utm_medium", value: "share" },
                { key: "utm_campaign", value: this.trackingService.GetCurrentPageName() },
            ]);
    }
}

export class ShareComponentModel {
    subHeader: string;
    shareSubjectText: string;
    shareContentText: string;
    shareSocialMediaText: string;
    shareUrl: string;
}
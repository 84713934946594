import { Injectable, ElementRef } from "@angular/core";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";

@Injectable({providedIn: 'root'})
export class SharingService {
    constructor(
        private platformService: PlatformHelpersService
    ) {

    }

    ShareFacebook(content: string, url: string): void {
        if (this.platformService.IsBrowserPlatform) {
            var facebookShareLink = "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url);
            this.ExecuteShare(facebookShareLink, "height=640,width=480");
        }
    }

    ShareTwitter(title: string, content: string, url: string): void {
        if (this.platformService.IsBrowserPlatform) {
            var twitterShareLink = "http://twitter.com/share?text=" + encodeURIComponent(title) + "&url=" + encodeURIComponent(url);
            this.ExecuteShare(twitterShareLink, "height=640,width=480");
        }
    }

    ShareWhatsapp(url: string, content: string): void {
        if (this.platformService.IsBrowserPlatform) {
            var whatsappShareLink = "https://api.whatsapp.com/send?text=" + encodeURIComponent(content) + " " + encodeURIComponent(url);
            this.ExecuteShare(whatsappShareLink, "height=640,width=480");
        }
    }

    ShareMail(title: string, content: string, url: string): void {
        if (this.platformService.IsBrowserPlatform) {
            if (!title) { title = ""; }
            var mailShareLink = "mailto:?subject=" + encodeURIComponent(title) + "&body=" + encodeURIComponent(content) + " " + encodeURIComponent(url);
            this.ExecuteShare(mailShareLink, "", false);
        }
    }

    CopyToClipboard(inputElement: any): void {
        if (this.platformService.IsBrowserPlatform) {
            var range = document.createRange();
            var el = (inputElement as ElementRef).nativeElement;
            el.contenteditable = true;
            el.readonly = false;
            el.select();

            range.selectNodeContents(el);
            var s = this.platformService.Window.getSelection();
            s.removeAllRanges();
            s.addRange(range);
            el.setSelectionRange(0, 999999);

            document.execCommand('copy');

            el.contenteditable = false;
            el.readonly = true;
        }
    }

    private ExecuteShare(link: string, size: string, inPopup: boolean = true) {
        try {
            ExecuteSharingHook(JSON.stringify({
                link: link,
                size: size
            }));
        }
        catch {
            if (inPopup === true) {
                this.platformService.Window.open(link, "", size);
            }
            else {
                this.platformService.Window.location.href = link;
            }

        }
    }
}
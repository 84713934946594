import { OnInit, Component, Input, ViewEncapsulation } from "@angular/core";
import { SharingComponentBase, ShareComponentModel } from "../../component.base.classes/sharing.component.base";

import { SharingService } from "src/app/services/sharing.module/sharing.service";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { PbIconComponent } from "src/app/controls/pb-icon/pb-icon.component";



@Component({
    selector: 'share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss'],
    standalone: true,
    imports: [PbIconComponent]
})

export class ShareComponent extends SharingComponentBase implements OnInit {
    @Input() set parameters(value: ShareComponentModel) {
        this.componentModel = value;
    };
    get parameters(): ShareComponentModel {
        return this.componentModel;
    }

    get copyLinkShareUrl(): string {
        return this.GetUtmUrl("copy-link");
    }

    constructor(
        shareService: SharingService,
        platformService: PlatformHelpersService,
        urlsService: UrlHelpersService,
        trackingService: TrackingService,
        applicationStateService: ApplicationStateService,
    ) {
        super(shareService, urlsService, platformService, trackingService, applicationStateService);
    }

    ngOnInit() { }

    OnClickedSharingItem(type: string) {
        this.PerformShare(type);
    }
}
